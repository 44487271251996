<template>
  <v-hover v-slot="{ hover }">
    <div
      class="blue-grey lighten-5 f-preview-container"
      :class="{ 'f-preview-left': model.align === 'bottom-left', 'f-preview-right': model.align === 'bottom-right', 'elevation-12': hover }"
      @click="e => $emit('click', e)"
    >
      <div class="text-center grey--text text--darken-3 d-flex justify-center align-start" style="position: absolute; width: 100%; height: 100%;">
        <div class="subtitle-1 pt-4">Предпросмотр</div>
      </div>
      <div class="f-preview-widget-container">
        <div
          class="yellow accent-2 elevation-4 d-flex justify-center align-center f-preview-widget"
          :style="widgetStyle"
        >
        </div>
      </div>
    </div>
  </v-hover>
</template>

<script>
export default {
  props: {
    value: { type: null, default: () => ({}) },
    scale: { type: Number, default: 2 }
  },
  data() {
    return {
      model: {}
    }
  },
  computed: {
    widgetStyle() {
      return {
        'margin-left': this.model.align === 'bottom-left' ? Math.floor(this.model.offsetX / this.scale) + 'px' : '0',
        'margin-right': this.model.align === 'bottom-right' ? Math.floor(this.model.offsetX / this.scale) + 'px' : '0',
        'margin-bottom': Math.floor(this.model.offsetY / this.scale) + 'px',
        'width': Math.floor(this.model.width / this.scale) + 'px',
        'height': Math.floor(this.model.height / this.scale) + 'px'
      }
    }
  },
  watch: {
    value: {
      deep: true,
      handler(value) {
        this.model = { ...(value || {}) }
      }
    }
  },
  mounted() {
    this.model = { ...(this.value || {}) }
  }
}
</script>

<style scoped>
  .f-preview-container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 200px;
    height: 240px;
    cursor: pointer;
    overflow: hidden;
    background-image:
      linear-gradient(rgba(220, 220, 220, .7) .1em, transparent .1em),
      linear-gradient(90deg, rgba(220, 220, 220, .7) .1em, transparent .1em);
    background-size: 10px 10px;
    background-repeat: repeat;
  }
  .f-preview-container.f-preview-left {
    border-radius: 0 0 0 16px;
    border-width: 1px 1px 2px 2px;
    border-style: solid solid dashed dashed;
    border-color: rgb(152, 166, 180)!important;
  }
  .f-preview-container.f-preview-right {
    border-radius: 0 0 16px 0;
    border-width: 1px 2px 2px 1px;
    border-style: solid dashed dashed solid;
    border-color: rgb(152, 166, 180)!important;
  }
  .f-preview-widget-container {
    display: flex;
    align-items: flex-end;
    flex: 1 1;
    padding: 0;
  }
  .f-preview-widget-container .f-preview-widget {
    width: 50px;
    height: 50px;
    border-radius: 6px;
    border: 1px dashed red!important;
  }
  .f-preview-left .f-preview-widget-container {
    justify-content: flex-start;
  }
  .f-preview-right .f-preview-widget-container {
    justify-content: flex-end;
  }
</style>
