<template>
  <v-container class="py-10">
    <v-row>
      <v-col>
        <div class="subtitle-1">Возможности виджета</div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-switch
          v-model="model.allowFinder"
          label="Разрешить посетителям выбирать конкретного консультанта"
          @change="emitUpdate"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-switch
          v-model="model.blurPreview"
          label="Показывать размытое изображение консультанта пока посетитель не инициирует звонок"
          @change="emitUpdate"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    value: { type: Object, default: () => null }
  },
  data() {
    return {
      model: {},
      defaults: { allowFinder: true, blurPreview: false },
      mounted: false
    }
  },
  watch: {
    value: {
      deep: true,
      handler(value) {
        this.setModel(value)
      }
    }
  },
  mounted() {
    this.setModel(this.value)
    this.mounted = true
  },
  methods: {
    setModel(value) {
      this.model = { ...this.defaults, ...(value || {}) }
      if (this.model.allowFinder === 'false') this.model.allowFinder = false
      if (this.model.blurPreview === 'false') this.model.blurPreview = false
    },
    emitUpdate() {
      if (!this.mounted) return
      this.$emit('input', this.model)
    }
  }
}
</script>

<style scoped>
</style>
