<template>
  <v-container class="py-10">
    <v-row>
      <v-col>
        <div class="subtitle-1">Текст виджета</div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-textarea
          v-model="model.previewTitle"
          label="Текст для заголовка виджета"
          outlined
          rows="3"
          @input="emitUpdate"
        />
      </v-col>
      <v-col cols="6">
        <v-textarea
          v-model="model.previewText"
          label="Текст на виджете, когда консультант на связи, но не ведет вещание"
          outlined
          rows="3"
          @input="emitUpdate"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-textarea
          v-model="model.offlineText"
          label="Текст на виджете, когда нет консультантов на связи"
          outlined
          rows="3"
          @input="emitUpdate"
        />
      </v-col>
      <v-col cols="6">
        <v-textarea
          v-model="model.chatStartMessage"
          label="Сообщение в чате, когда посетитель заходит в него впервые"
          outlined
          rows="3"
          @input="emitUpdate"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    value: { type: Object, default: () => null }
  },
  data() {
    return {
      model: {},
      defaults: {
        previewTitle: '',
        previewText: 'Видеовиджет',
        offlineText: 'Нет консультантов на связи',
        chatStartMessage: 'Готов ответить на вопросы'
      },
      mounted: false
    }
  },
  watch: {
    value: {
      deep: true,
      handler(value) {
        this.setModel(value)
      }
    }
  },
  mounted() {
    this.setModel(this.value)
    this.mounted = true
  },
  methods: {
    setModel(value) {
      this.model = { ...this.defaults, ...(value || {}) }
    },
    emitUpdate() {
      if (!this.mounted) return
      this.$emit('input', this.model)
    }
  }
}
</script>

<style scoped>
</style>
