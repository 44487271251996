<template>
  <v-container class="py-10">
    <v-row>
      <v-col>
        <div class="subtitle-1">Размер и местоположение</div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="col-sm-8">
        <v-row>
          <v-col cols="12">
            <v-select
              v-model="model.align"
              outlined
              label="Прижать к краю страницы"
              :items="alignOptions"
              @change="emitUpdate"
            />
          </v-col>
          <v-col cols="12" class="col-sm-6 col-md-3">
            <v-text-field
              v-model="model.width"
              type="number"
              outlined
              label="Ширина"
              suffix="px"
              @input="emitUpdate"
            />
          </v-col>
          <v-col cols="12" class="col-sm-6 col-md-3">
            <v-text-field
              v-model="model.height"
              type="number"
              outlined
              label="Высота"
              suffix="px"
              @input="emitUpdate"
            />
          </v-col>
          <v-col cols="12" class="col-sm-6 col-md-3">
            <v-text-field
              v-model="model.offsetX"
              type="number"
              outlined
              label="Отступ сбоку"
              suffix="px"
              @input="emitUpdate"
            />
          </v-col>
          <v-col cols="12" class="col-sm-6 col-md-3">
            <v-text-field
              v-model="model.offsetY"
              type="number"
              outlined
              label="Отступ снизу"
              suffix="px"
              @input="emitUpdate"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="col-sm-4 d-flex justify-center">
        <videochat-position-preview :value="model" @click="previewDialog = true" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="subtitle-1">Правила показа виджета</div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-radio-group v-model="model.visibility" mandatory class="ma-0" @change="emitUpdate">
          <v-radio
            v-for="(item, idx) in visibilityOptions"
            :key="idx"
            :value="item.val"
            class="py-3"
          >
            <template v-slot:label>
              <div class="pl-3">
                <div class="grey--text text--darken-3" v-html="item.title" />
                <p class="body-2 grey--text text--darken-1 ma-0" v-html="item.desc" />
              </div>
            </template>
          </v-radio>
        </v-radio-group>
      </v-col>
    </v-row>

    <v-dialog
      v-model="previewDialog"
      fullscreen
      hide-overlay
      transition="scale-transition"
    >
      <div v-resize="onPreviewContainerResize" class="preview-container">
        <div class="px-10 pt-10 pb-5 text-center" style="background-color: #ffffff90; width: 400px; position: absolute; left: calc(50% - 200px); top: 50px;">
          <p class="subtitle-2 pb-5">Поместите желтый квадрат в место, где должен появляться виджет. Потяните за угол чтобы задать размер.</p>
          <v-btn large color="secondary" @click="onPreviewClose">Закрыть</v-btn>
        </div>
        <vue-draggable-resizable
          active
          prevent-deactivation
          :parent="false"
          :w="parseInt(model.width)"
          :h="parseInt(model.height)"
          :min-width="50"
          :min-height="50"
          :max-width="400"
          :max-height="400"
          :x="model.align === 'bottom-right' ? windowSize.w - model.offsetX - parseInt(model.width) : model.offsetX"
          :y="windowSize.h - model.offsetY - parseInt(model.height)"
          :z="999"
          class-name="preview-box"
          @resizing="onResizing"
          @dragging="onDragging"
        />
      </div>
    </v-dialog>
  </v-container>
</template>

<script>
import VideochatPositionPreview from './videochat-position-preview'
import VueDraggableResizable from 'vue-draggable-resizable'
import 'vue-draggable-resizable/dist/VueDraggableResizable.css'

export default {
  components: { VueDraggableResizable, VideochatPositionPreview },
  props: {
    value: { type: Object, default: () => null }
  },
  data() {
    return {
      model: {},
      defaults: { align: 'bottom-right', offsetX: 10, offsetY: 10, visibility: 'always' },
      alignOptions: [{ value: 'bottom-left', text: 'Прижать к левому краю' }, { value: 'bottom-right', text: 'Прижать к правому краю' }],
      visibilityOptions: [
        { val: 'always', title: 'Всегда показывать виджет', desc: 'Всегда отображать виджет на сайте' },
        { val: 'if_video', title: 'Показывать, если консультант транслирует видео-приглашение', desc: 'Отображать виджет только если хотя бы один консультант ведет приглашающую видео-трансляцию' },
        { val: 'if_online', title: 'Показывать, если есть консультант онлайн', desc: 'Отображать виджет только если хотя бы один консультант онлайн' },
        { val: 'never', title: 'Не показывать виджет', desc: 'Виджет, по умолчанию, скрыт. Открыть его можно только если вы добавите кнопку на сайт самостоятельно' }
      ],
      mounted: false,
      previewDialog: false,
      windowSize: { w: 0, h: 0 }
    }
  },
  watch: {
    value: {
      deep: true,
      handler(value) {
        this.setModel(value)
      }
    }
  },
  mounted() {
    this.setModel(this.value)
    this.mounted = true
  },
  methods: {
    setModel(value) {
      this.model = { ...this.defaults, ...(value || {}) }
    },
    emitUpdate() {
      if (!this.mounted) return
      this.$emit('input', this.model)
    },
    recalcPosition(left, top, width, height) {
      this.model.align = (2 * left + width > this.windowSize.w) ? 'bottom-right' : 'bottom-left'
      this.model.width = width
      this.model.height = height
      this.model.offsetX = (this.model.align === 'bottom-right') ? this.windowSize.w - left - width : left
      this.model.offsetY = this.windowSize.h - top - height
    },
    onResizing(left, top, width, height) {
      this.recalcPosition(left, top, width, height)
    },
    onDragging(left, top) {
      this.recalcPosition(left, top, parseInt(this.model.width), parseInt(this.model.height))
    },
    onPreviewContainerResize() {
      this.windowSize = { w: window.innerWidth, h: window.innerHeight }
    },
    onPreviewClose() {
      this.previewDialog = false
      this.emitUpdate()
    }
  }
}
</script>

<style scoped>
  .preview-container {
    width: 100%;
    height: 100%;
    background-color: #000000CC;
    overflow: hidden;
  }
  .preview-box {
    cursor: grab;
    background-color: #FFFF00;
    border-radius: 10px;
  }
</style>
