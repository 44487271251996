<template>
  <v-container fluid class="fill-height align-start pa-0 blue-grey lighten-5">
    <v-row no-gutters class="py-6 px-sm-10">
      <v-col cols="12">
        <v-expansion-panels :value="0" :readonly="false">
          <v-expansion-panel>
            <v-expansion-panel-header>
              <v-icon size="32" color="primary" class="flex-grow-0 pr-5">mdi-resize</v-icon>
              <span class="title grey--text text--darken-2">Положение и видимость</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <widget-position-partial v-model="model" @input="onModelChanged" />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <v-icon size="32" color="primary" class="flex-grow-0 pr-5">mdi-cog-outline</v-icon>
              <span class="title grey--text text--darken-2">Функциональные возможности</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <widget-feature-partial v-model="model" @input="onModelChanged" />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <v-icon size="32" color="primary" class="flex-grow-0 pr-5">mdi-palette</v-icon>
              <span class="title grey--text text--darken-2">Внешний вид</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <widget-appearance-partial v-model="model" @input="onModelChanged" />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel v-if="false">
            <v-expansion-panel-header>
              <v-icon size="32" color="primary" class="flex-grow-0 pr-5">mdi-cellphone</v-icon>
              <span class="title grey--text text--darken-2">Версия для мобильных устройств</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              here
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import WidgetAppearancePartial from './partials/videochat-appearance'
import WidgetPositionPartial from './partials/position/videochat-position'
import WidgetFeaturePartial from './partials/videochat-features'

export default {
  components: { WidgetAppearancePartial, WidgetPositionPartial, WidgetFeaturePartial },
  props: {
    value: { type: null, default: () => {} }
  },
  data() {
    return {
      defaults: {
        // Position
        align: 'bottom-right',
        offsetX: 10,
        offsetY: 10,
        visibility: 'always',
        // Appearance
        width: 200,
        height: 200,
        previewTitle: '',
        previewText: 'Видеовиджет',
        // Features
        allowFinder: true,
        blurPreview: false
      },
      model: {}
    }
  },
  watch: {
    value(value) {
      this.model = { ...this.defaults, ...(value || {}) }
    }
  },
  mounted() {
    this.model = { ...this.defaults, ...(this.value || {}) }
  },
  methods: {
    onModelChanged() {
      this.$emit('input', this.model)
    }
  }
}
</script>

<style scoped>
</style>
